












































import { Component, Watch, Vue } from "vue-property-decorator";
import { User } from "@/types";
import { namespace } from "vuex-class";
const FiltersModule = namespace("FiltersModule");
@Component
export default class FilterItem extends Vue {
  @Watch("search")
  async onSearchChanged(query: string) {
    // Items have already been requested
    if (this.loading) return;
    this.searchUser(query);
  }
  //
  // COMPUTED
  @FiltersModule.State
  readonly users!: User[];
  get model() {
    return this.$store.state.FiltersModule.filters.userIds;
  }
  set model(payload: string) {
    this.$store.commit("FiltersModule/FILTER_USER", payload);
    this.search = null;
    this.$emit("filter");
  }
  //
  // DATA
  private loading = false;
  private search = null;
  //
  // METHODS
  searchUser(query: string) {
    this.loading = true;
    setTimeout(async () => {
      await this.$store.dispatch("FiltersModule/fetchUsers", query);
      this.loading = false;
    });
  }
  //
  // HOOKS
  created() {
    this.searchUser("");
  }
}
